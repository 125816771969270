// 都道府県をアルファベットに変換
export function prefToAlphabet(pref: string) {
  switch (pref) {
    case "大阪府":
      return "osaka";
      break;
    case "京都府":
      return "kyoto";
      break;
    case "兵庫県":
      return "hyogo";
      break;
    case "奈良県":
      return "nara";
      break;
    case "和歌山県":
      return "wakayama";
      break;
    case "滋賀県":
      return "shiga";
      break;
  }
}

// アルファベットを都道府県に変換
export function alphabetToPref(alphabet: string) {
  switch (alphabet) {
    case "osaka":
      return "大阪府";
      break;
    case "kyoto":
      return "京都府";
      break;
    case "hyogo":
      return "兵庫県";
      break;
    case "nara":
      return "奈良県";
      break;
    case "wakayama":
      return "和歌山県";
      break;
    case "shiga":
      return "滋賀県";
      break;
  }
}

// 市区町村をアルファベットに変換　other -> その他用
export function alphabetToCity(city: string) {
  if (city === "other") {
    return "その他";
  }
  return city;
}

// 都道府県の表示順
export const prefOrder = [
  "osaka",
  "hyogo",
  "kyoto",
  "nara",
  "shiga",
  "wakayama",
] as const;
